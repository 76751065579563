import React from 'react'

import MainSectionWrapper from '../main/MainSectionWrapper'
import PrimarySectionHeading from '../PrimarySectionHeading'
import CertificateItem from './CertificateItem'
import { ICertificate } from '../../../../model/Resume'
import styled from 'styled-components'

interface ICertificateSectionProps {
  certificateList: ICertificate[]
}

const CertificateSection = ({ certificateList }: ICertificateSectionProps) => (
  <MainSectionWrapper>
    <PrimarySectionHeading>Certificates</PrimarySectionHeading>
    {certificateList.map(item => (
      <CertificateItem key={`${item.name}-${item.date}`} item={item} />
    ))}
  </MainSectionWrapper>
)

export default CertificateSection
