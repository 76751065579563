import React from 'react'
import styled from 'styled-components'
import { ICertificate } from '../../../../model/Resume'

import * as styles from '../../../../styles/commonStyles'
import LocationIcon from '../../../icons/LocationIcon'
import CalendarIcon from '../../../icons/CalendarIcon'

const StyledCertificateItem = styled.div`
  padding-bottom: 20px;
`
const Degree = styled.div`
  font-weight: bold;
`
const LineContainer = styled.div`
  display: flex;
  align-items: left;
  padding-bottom: 3px;
`
const Institution = styled.div`
  font-weight: bold;
  color: ${styles.text.color.highlight};
  margin-right: 5px;
`
const Location = styled.div`
  margin-right: 8px;
  color: ${styles.text.color.secondary};
`
const Dates = styled.div`
  margin-right: 7px;
  font-style: italic;
  color: ${styles.text.color.secondary};
`
const List = styled.ul`
  margin-top: 3px;
`

const Company = styled.div`
  font-weight: bold;
  color: ${styles.text.color.highlight};
  margin-right: 5px;
`

const Name = styled.div`
  font-weight: bold;
`

const URL = styled.div`
  color: ${styles.text.color.secondary};
`

interface ICertificateItemProps {
  item: ICertificate
}

const Link = styled.a`
  color: ${styles.text.color.secondary};
  position: relative;
  top: -2px;
`

const CertificateItem = ({ item }: ICertificateItemProps) => (
  <StyledCertificateItem>
      <Name>{item.name}</Name>
      <LineContainer>
        <Company>{item.company}</Company>
        <LocationIcon />
        <Location>{item.location}</Location>
        <CalendarIcon />
        <Dates>{`${item.date}`}</Dates>
      </LineContainer>
      <Link href={item.url}>{item.url}</Link>
  </StyledCertificateItem>
)

export default CertificateItem
