import React from 'react'
import styled from 'styled-components'

import ColumnLayout from './columnLayout/ColumnLayout'
import PrimaryColumn from './primaryColumn/PrimaryColumn'
import SecondaryColumn from './secondaryColumn/SecondaryColumn'

import * as styles from '../../styles/commonStyles'

import { IResume } from '../../model/Resume'
import { A4_WIDTH_CM, A4_HEIGHT_CM } from '../../constants/constants'

const StyledPage3 = styled.div`
  width: ${A4_WIDTH_CM}cm;
  height: ${A4_HEIGHT_CM + 2}cm;
  padding: 0 0.5cm 0 1.5cm;
`

interface IPage2Props {
  resume: IResume
  printable: boolean
}


interface StyledProps {
  printable: boolean
}


const StyledForPage3 = styled.div<StyledProps>`
  padding-top: 220px;
`
const Header = styled.div<StyledProps>`
  background: ${styles.background.secondary};
  font-size: ${styles.text.size.headingLarge};
`

const Page3 = ({ resume, printable }: IPage2Props) => (
  <StyledPage3>
    <ColumnLayout>
      <PrimaryColumn resume={resume} page={3} printable={printable} />
      <SecondaryColumn resume={resume} page={3} printable={printable} />
    </ColumnLayout>
  </StyledPage3>
)

export default Page3
