import React from 'react'
import styled from 'styled-components'
import SecondarySection from './SecondarySection'
import { IResume } from '../../../model/Resume'
import { A4_HEIGHT_CM } from '../../../constants/constants'
import SectionHeading from './SecondarySectionHeading'

import * as styles from '../../../styles/commonStyles'

interface ISecondaryColumnProps {
  resume: IResume
  page: number
  printable: boolean
}

interface StyledProps {
  printable: boolean
}

const StyledForPage1 = styled.div<StyledProps>`
  background: ${styles.background.secondary};
  padding-top: ${props => (props.printable ? '223px' : '203px')};
  padding-left: 15px;
  margin-top: ${props => (props.printable ? '-20px' : '0px')};
`

const StyledForPage2 = styled.div<StyledProps>`
  background: ${styles.background.secondary};
`

const StyledForPage3 = styled.div<StyledProps>`
  background: ${styles.background.secondary};
  height: ${props => (props.printable ? A4_HEIGHT_CM + 6 : A4_HEIGHT_CM + 2)}cm;
`

const LineContainer = styled.div`
  display: flex;
  align-items: left;
`

const LanguageSection = styled.div`
  margin-bottom: 40px;
`

const Language = styled.div`
  width: 47px;
`

const LanguageDivider = styled.div`
  padding-left: 6px;
  padding-right: 6px;
`

const SecondaryColumn = ({
  resume,
  page,
  printable
}: ISecondaryColumnProps) => {
  const { languages, skills, certificates, interests } = resume

  return (
    <>
      {page === 1 && (
        <StyledForPage1 printable={printable}>
          <LanguageSection>
          <SectionHeading>Languages</SectionHeading>
            {languages && languages.length != 0 && languages.map(item => (
              <LineContainer key={`${item.name} - ${item.fluency}`}>
                  <Language>{item.name}</Language><LanguageDivider>-</LanguageDivider><div>{item.fluency}</div>
              </LineContainer>
            ))}
          </LanguageSection>
          {skills && skills.length != 0 && (
            <SecondarySection title="Skills" listItems={skills} />
          )}
          {interests && interests.length != 0 && (
            <SecondarySection title="Interests" listItems={interests} />
          )}
        </StyledForPage1>
      )}

      {page === 2 && <StyledForPage2 printable={printable} />}

      {page === 3 && <StyledForPage3 printable={printable} />}
    </>
  )
}

export default SecondaryColumn
